<template>
  <div class="activityReport">
    <!-- <div class="activityTitle">
                  <img src="@/assets/bracelet_img/33.png" mode="">
                  <span>活动报告</span>
             </div>
             <div class="blackBar">
                  <div class="greenBar mar_10"></div>
                  <span class="mar_10">一</span>
                  <span class="mar_10">起</span>
                  <span class="mar_10">社</span>
                  <span class="mar_10">交</span>
                  <span class="mar_10">赚</span>
                  <span>钱</span>
             </div> -->
    <watch-head :title="$t('活动报告')"
                :img="require('@/assets/bracelet_img/33.png')"></watch-head>
    <div class="activityReportBox">
      <div class="datePreset">
        <div class="status" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{$t('日期预设')}}</div>
        <div class="box">
          <div class="calendar">
            <div class="title">
              <div :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{ month }}{{$t('月')}}{{ year }}</div>
              <img @click="dateShow = true"
                   class="pointer"
                   src="@/assets/bracelet_img/25.png"
                   alt="">
            </div>
            <Calendar ref="Calendar"
                      :textTop="['SU','MO', 'TU', 'WE', 'TH', 'FR', 'SA']"
                      :sundayStart='true'
                      v-on:choseDay="clickDay"></Calendar>
          </div>
        </div>

        <div class="time">
          <div class="title" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{ month }}{{$t('月')}}{{ day }}{{$t('日')}} {{ year }}</div>
          <div class="item">
            <div class="line"></div>
            <div class="right">
              <div class="text" :class="{'vn-bold-italic': $i18n.locale == 'vn'}">{{ planData.name ? planData.name : $t('暂无计划') }}</div>
              <!-- <div class="dt">00:02 - 01:30</div> -->
              <div class="data">
                <img src="@/assets/bracelet_img/17.png"
                     alt="">
                <span>{{ planData.step_count_begin }} KM</span>
              </div>
              <div class="data">
                <img src="@/assets/bracelet_img/15.png"
                     alt="">
                <span>{{ planData.heart_rate }} BPM</span>
              </div>
              <div class="data">
                <img src="@/assets/bracelet_img/16.png"
                     alt="">
                <span>{{ planData.calories_begin }} KCAL</span>
              </div>
              <div class="data">
                <img src="@/assets/bracelet_img/19.png"
                     alt="">
                <span>{{ planData.excercise_time }} {{$t('分钟')}}</span>
              </div>
              <div class="data">
                <img src="@/assets/bracelet_img/18.png"
                     alt="">
                <span>- {{ planData.use_blood }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="btm">
      <div class="request_add">
        <div class="left">
          <div class="box">
            <img src="@/assets/bracelet_img/5.png"
                 alt="">
          </div>
        </div>
        <div class="right">
          <span class="mar_ri_1">M</span>
          <span class="mar_ri_1">E</span>
          <span class="mar_ri_1">T</span>
          <span class="mar_ri_1">A</span>
          <span class="mar_ri_1">N</span>
          <span class="mar_ri_1">E</span>
          <span class="mar_ri_1">B</span>
          <span class="mar_ri_1">U</span>
          <span class="mar_ri_1">L</span>
          <span class="mar_ri_1">A</span>
          <span>S</span>
        </div>
      </div>
    </div>

    <!-- 月份弹出层 -->
    <van-popup v-model="dateShow"
               position="bottom">
      <van-datetime-picker v-model="currentDate"
                           @confirm="confirmDate"
                           @cancel="cancelDate"
                           type="year-month"
                           :confirm-button-text="$t('确定')"
                           :cancel-button-text="$t('取消')"
                           :class="{'vn-bold-italic': $i18n.locale == 'vn'}"
                           :title="$t('选择年月')" />
    </van-popup>
  </div>
</template>
   
<script>
import Calendar from "vue-calendar-component";
import watchHead from '@/components/watchHead'
import { getDateInfo } from '../../../api/api'
import { mapState } from 'vuex'
export default {
  components: {
    Calendar,
    watchHead
  },
  data () {
    return {
      currentDate: '',
      dateShow: false,
      timer: null,
      year: new Date().getFullYear(), //获取完整的年份(4位)
			month: new Date().getMonth() + 1, //获取当前月份(0-11,0代表1月)
			day: new Date().getDate(), //获取当前日(1-31)

      planData: {
        calories_begin: 0,
        excercise_time: 0,
        heart_rate: 0,
        name: null,
        step_count_begin: 0,
        use_blood: 0,
      }
    }
  },
  computed: {
    ...mapState([
      'web3',
    ]),
  },
  mounted(){
    this.waitForGlobal();
  },
  methods: {
    getPlanData(date){
      getDateInfo({date}).then(res => {
        if(res.code == 0){
          this.planData = res.data
        }
      })
    },
    clickDay (data) {
      console.log("选中了---", data); //选中某天
      let date = data.split('/')
      this.year = date[0]
      this.month = date[1]
      this.day = date[2]

      let time,year,month,day;
      year = date[0]
      month = date[1] < 10 ? '0' + date[1] : date[1]
      day = date[2] < 10 ? '0' + date[2] : date[2]
      time = year + '-' + month + '-' + day
      this.getPlanData(time);
    },
    cancelDate(){
      this.dateShow = false
    },
    confirmDate (data) {
      let d = new Date(data);
      let y = d.getFullYear();
      let m = d.getMonth() + 1;
      let s = d.getDate();
      if (m < 10) m = "0" + m;
      if (s < 10) s = "0" + s;
      let datetime = y + "-" + m + "-" + s
      console.log("选中了月份----", datetime);
      // this.$refs.Calendar.ChoseMonth(datetime, false);//false第二个参数表示不选中当天
      this.$refs.Calendar.ChoseMonth(datetime);
      let date = datetime.split('-')
      this.year = date[0]
      this.month = date[1]
      this.day = date[2]
      this.dateShow = false
      this.getPlanData(datetime);
    },
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress')) {
        window.clearTimeout(this.timer);
        this.getPlanData();
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
        ,100);
      }
    },
  }
}
</script>
   
<style lang="less" scoped>
// div{
// 	box-sizing: border-box;
// }
.activityReport {
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 60px;
  background-image: url(~@/assets/bracelet_img/26.png);
  background-repeat: repeat-y;
  background-size: 100% 100%;
  color: #ffffff;
  //   .activityTitle {
  //     padding: 0 8px;
  //     background-color: #6480f3;
  //     display: flex;
  //     align-items: center;
  //     font-family: '071-SSRuiFengTi';
  //     font-size: 45px;
  //     margin-bottom: 4px;
  //     img {
  //       width: 35px;
  //       height: 35px;
  //       margin-right: 8px;
  //     }
  //   }
  //   .blackBar {
  //     // padding: 2rpx;
  //     background-color: #2b2b2b;
  //     display: flex;
  //     align-items: center;
  //     font-family: 'TsangerYuYangT-W03';
  //     font-size: 12px;
  //     .greenBar {
  //       width: 23px;
  //       height: 10px;
  //       background-color: #a7f264;
  //     }
  //     .mar_10 {
  //       margin-right: 5px;
  //     }
  //   }
  .activityReportBox {
    padding: 35px 32px 16px;
    .datePreset {
      width: 100%;
      // height: 148vw;
      background-color: #ffffff;
      border-radius: 16px;
      padding: 20px 0 24px;
      .status {
        width: 55%;
        background-image: linear-gradient(115deg, #8ff9f6 0%, #ffffff 100%),
          linear-gradient(#2b2b2b, #2b2b2b);
        background-blend-mode: normal, normal;
        font-family: '071-SSRuiFengTi';
        font-size: 24px;
        color: #2b2b2b;
        padding: 2px 12px 8px;
      }
      .box {
        padding: 20px 16px 28px;
        .calendar {
          width: 100%;
          // height: 70vw;
          box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.1);
          border-radius: 14px;
          .title {
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: 'AeroMaticsDisplayBold';
            font-size: 24px;
            color: #2b2b2b;
            border-bottom: 4px solid #94f9f6;
            img {
              width: 20px;
              height: 12px;
            }
          }
          .wh_container {
            /deep/.wh_content_all {
              background-color: #fff;
              border-radius: 0 0 14px 14px;
            }
            /deep/.wh_item_date {
              color: #2b2b2b;
            }
            // /deep/.wh_item_date:hover{
            //   background-color: #7741FA;
            //   border-radius: 7.5px;
            //   color: #fff;
            // }
            /deep/.wh_top_tag {
              color: #2b2b2b;
            }
            /deep/.wh_top_changge li {
              color: #2b2b2b;
            }
            /deep/.wh_top_changge {
              display: none;
            }
            /deep/.wh_other_dayhide {
              color: #bfbfbf;
            }
            /deep/.wh_chose_day {
              background-color: #7741fa;
              border-radius: 8px;
              color: #fff;
            }
            /deep/.wh_isToday {
              background-color: #8ff9f6;
              border-radius: 8px;
            }
          }
        }
      }
      .time {
        padding-left: 25px;
        .title {
          font-family: 'AeroMaticsDisplayBold';
          font-size: 24px;
          color: #2b2b2b;
          margin-bottom: 12px;
        }
        .item {
          display: flex;
          .line {
            width: 5px;
            height: 165px;
            background-color: #0ed5d9;
            margin-right: 14px;
          }
          .right {
            .text {
              font-family: 'TsangerYuYangT-W05';
              font-size: 27px;
              color: #2b2b2b;
              margin-bottom: 15px;
            }
            .dt {
              font-family: 'AeroMaticsItalic';
              font-size: 20px;
              color: #6111a8;
              margin-bottom: 20px;
            }
            .data {
              font-family: 'AeroMaticsItalic';
              font-size: 18px;
              color: #6111a8;
              img {
                width: 16px;
                height: 15px;
                margin-right: 10px;
              }
            }
          }
        }
        .mar_btm_15 {
          margin-bottom: 15px;
        }
      }
    }
  }
  .btm {
    width: 100%;
    font-family: '071-SSRuiFengTi';
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 8px;
    .request_add {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        width: 17%;
        height: 52px;
        background-color: rgba(43, 43, 43, 0.5);
        border-radius: 0 0 36px 0;
        padding: 8px 8px 8px 0;
        .box {
          background-color: #2b2b2b;
          border-radius: 0 0 32px 0;
          border: solid 3px #7736fe;
          opacity: 0.9;
          display: flex;
          align-items: center;
          justify-items: center;
          padding-left: 8px;
          height: 100%;
          img {
            width: 28px;
            height: 20px;
          }
        }
      }
      .right {
        width: 82%;
        height: 52px;
        display: flex;
        align-items: center;
        background-color: rgba(43, 43, 43, 0.5);
        border-radius: 36px 0 0 0;
        justify-content: center;

        font-family: 'KenyanCoffeeRg-BoldItalic';
        font-size: 30px;
        color: #928da3;
        .mar_ri_1 {
          margin-right: 10px;
        }
      }
    }
  }
}
@media screen and (min-width: 480px){
  //在这里写非小屏幕设备的样式  -- 非手机
  .activityReport{
    .btm {
      width: 430px;
    }
  }
  /deep/.van-popup--bottom{
    width: 430px;
    left: calc(50% - 215px);
  }
}
</style>
   